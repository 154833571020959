import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

import Title from './title.png';
import Logo from './hotbunnies_logo.png';
import Img1 from './1.png';
import Img2 from './2.png';

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main
      style={
        window.innerWidth < 1024 ?
        {
          display: 'grid',
          gridTemplateColumns: '1fr',
          height: '100vh',
        }
        :
        {
          display: 'grid',
          gridTemplateColumns: '25% 1fr 25%',
          height: '100vh',
        }
      }
    >
      <div style={{display:'none'}}>
        {wallet && (
          <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
        )}
        {wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>}
        {wallet && <p>Total Available: {itemsAvailable}</p>}
        {wallet && <p>Redeemed: {itemsRedeemed}</p>}
        {wallet && <p>Remaining: {itemsRemaining}</p>}
      </div>
        <div style={
        window.innerWidth < 1024 ?
        {
          display: 'none',
        }
        :
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end' 
        }}
        >
          <img style={{width: '54%'}} src={Img1} alt='' />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'        
        }}>
          <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '500px',
            }}
          >
            <img style={{width: '100%'}} src={Logo} alt="" />
            <img style={{width: '100%', marginTop: '-30px'}} src={Title} alt="" />
            <div
              style={{
                marginBottom: '20px',
                paddingLeft: '50px',
                paddingRight: '50px',
                textTransform: 'uppercase',
                fontSize: '25px',
                textAlign: 'center',
              }}
            >
              - Welcome to Hot Bunnies NFT and our Public Main Sale of 7791 NFTs - 
            </div>
            <div
              style={{
                marginBottom: '20px',
                paddingLeft: '50px',
                paddingRight: '50px',
                textAlign: 'center',
                lineHeight: 1.4
              }}
            >
              Here you can connect your Phantom Wallet and MINT our Public Main sale drop of 7791 Hot Bunnies NFTs.
            </div>
            {wallet && (
              <div
                style={{
                  marginBottom: '30px',
                  paddingLeft: '50px',
                  paddingRight: '50px',
                  textAlign: 'center',
                  lineHeight: 1.4
                }}
              >
                <b>{itemsRemaining} / {itemsAvailable}</b>
              </div>
            )}
            <MintContainer>
              {!wallet ? (
                <ConnectButton
                  style={{
                    marginTop: '20px', 
                    marginBottom: '40px', 
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    fontSize: '26px',
                    fontWeight: 600,
                    color: '#FFFFFF',
                    backgroundColor: '#000000',
                    borderRadius: '40px'
                  }}
                >
                  Connect Wallet
                </ConnectButton>
              ) : (
                <MintButton
                  disabled={isSoldOut || isMinting || !isActive}
                  onClick={onMint}
                  variant="contained"
                  style={{
                    marginTop: '20px', 
                    marginBottom: '40px', 
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    fontSize: '26px',
                    fontWeight: 600,
                    color: '#FFFFFF',
                    backgroundColor: '#000000',
                    borderRadius: '40px'
                  }}
                >
                  {isSoldOut ? (
                    "SOLD OUT"
                  ) : isActive ? (
                    isMinting ? (
                      <CircularProgress />
                    ) : (
                      "MINT"
                    )
                  ) : (
                    <Countdown
                      date={startDate}
                      onMount={({ completed }) => completed && setIsActive(true)}
                      onComplete={() => setIsActive(true)}
                      renderer={renderCounter}
                    />
                  )}
                </MintButton>
              )}
            </MintContainer>
            <div
              style={{
                marginBottom: '20px',
                paddingLeft: '50px',
                paddingRight: '50px',
                textAlign: 'center',
                lineHeight: 1.4
              }}
            >
              If the browser is slow or it takes time to connect your wallet please be patient as we have a lot of traffic at the moment. Just wait and you will be able to MINT.
            </div>
            <div
              style={{
                marginBottom: '20px',
                paddingLeft: '50px',
                paddingRight: '50px',
                textAlign: 'center',
                textTransform: 'uppercase',
                lineHeight: 1.4
              }}
            >
              <b>See you guys at The Bunny Palace !</b>
            </div>
            <a href='https://hotbunniesnft.com/' style={{color: '#FFFFFF', marginBottom: '20px'}}>
              Back to Hot Bunnies
            </a>
          </div>
        </div>
        <div style={
        window.innerWidth < 1024 ?
        {
          display: 'none',
        }
        :
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end' 
        }}
        >
          <img style={{width: '80%'}} src={Img2} alt='' />
        </div>
    
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
